<template>
    <back-button :route="{ name: 'products' }"/>
    <div class="flex flex-col my-3">
        <user-heading
            v-if="storeProduct"
            :user="storeProduct.user"
        ></user-heading>

        <nav-tabs :tabs="tabs" class="mb-5" />

        <div class="flex flex-wrap items-center">
            <img
                v-if="storeProduct.cover_picture"
                :src="storeProduct.cover_picture"
                style="width: 38px; height: 39px"
            />
            <svg
                v-else
                style="width: 38px; height: 39px"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enable-background="new 0 0 226.8 226.8"
                version="1.1"
                viewBox="0 0 226.8 226.8"
                xml:space="preserve"
            >
                <path
                    d="M113.3 226.8h-94c-5.5 0-10.2-1.8-14-5.7-3.7-3.8-5.3-8.4-5.3-13.6V104.2v-85C0 13.1 2.2 8 7 4.2c3.6-3 7.8-4.2 12.5-4.2H207.4c6.2 0 11.4 2.1 15.4 7 3 3.6 4.2 7.8 4.2 12.4v188c0 5.3-1.5 9.9-5.3 13.8-3.9 4-8.6 5.7-14.1 5.7h-79.4c-5.1-.1-10-.1-14.9-.1zm-66-146h70.8c1.8 0 3.5.1 4.3 2.1.9 2-.3 3.3-1.6 4.6l-23.1 23.1c-2.2 2.2-3.3 4.7-3.3 7.8v64.7c0 1.3.3 1.7 1.7 1.7 11.3-.1 22.7 0 34 0 2.1 0 2.1 0 2.1-2.1V82.9c0-2.1 0-2.1 2.2-2.1h43.3c.6 0 1.1 0 1.7-.1 0-.4.1-.6.1-.8V53.6c0-1.3-.7-1.2-1.5-1.2H48.7c-.5 0-.9 0-1.5.1.1 9.5.1 18.8.1 28.3z"
                ></path>
            </svg>

            <h2 class="ml-2 text-lg capitalize dark:text-white tex">
                {{ storeProduct.name }}
            </h2>

            <product-status
                class="ml-2"
                :status="storeProduct.status"
            />

            <span
                v-if="
                    storeProduct.product_showcase &&
                    storeProduct.product_showcase.is_recommended
                "
                class="inline-block px-2 py-1 mr-1 text-xs font-semibold text-white uppercase bg-indigo-500 rounded last:mr-0"
            >
                Recomendado
            </span>
            <span
                v-if="
                    storeProduct.product_showcase &&
                    storeProduct.product_showcase.is_best_rated
                "
                class="inline-block px-2 py-1 mr-1 text-xs font-semibold text-white uppercase bg-blue-500 rounded last:mr-0"
            >
                Avaliado
            </span>

            <button
                type="button"
                class="inline-flex items-center justify-center w-full px-4 py-1 mt-3 text-base font-medium text-white bg-indigo-500 border border-indigo-500 rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                style="margin-left: auto"
                @click="openModal()"
            >
                <font-awesome-icon
                    icon="fa-solid fa-pencil"
                    class="mr-1 dark:text-white"
                    aria-hidden="true"
                />
                Editar
            </button>
        </div>
    </div>

    <div class="grid grid-cols-3 gap-4" v-if="storeProduct">
        <div class="col-span-2">
            <div
                class="w-full min-w-0 mb-4 overflow-hidden bg-white rounded-lg h-50 dark:bg-gray-800 drop-shadow-sm"
            >
                <div
                    class="w-full min-w-0 p-5 mb-4 overflow-hidden bg-white rounded-lg h-50 dark:bg-gray-800 drop-shadow-sm"
                >
                    <div class="grid grid-cols-4 gap-3 mb-4">
                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >NOME</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">{{
                                storeProduct.name
                            }}</span>
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >PERMITE VITRINE</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">
                                <span
                                    class="text-sm font-medium mr-2 px-2.5 py-1.5 rounded"
                                    :class="{
                                        'bg-green-500 text-white':
                                            storeProduct.allow_showcase,
                                        'bg-red-500 text-white':
                                            !storeProduct.allow_showcase,
                                    }"
                                >
                                    {{
                                        storeProduct.allow_showcase
                                            ? "Sim"
                                            : "Não"
                                    }}
                                </span>
                            </span>
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >VISIBILIDADE</span
                            >
                                <span class="text-gray-600 dark:text-gray-200">
                                        <span
                                        v-if="
                                            storeProduct.is_hidden
                                        "
                                        class="flex flex-wrap"
                                    >
                                        <CheckCircleIcon
                                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                            aria-hidden="true"
                                        />
                                    Produto Oculto
                                    <br/>
                                    <button
                                        @click.prevent="toggle"
                                        type="button"
                                        :class="{'cursor-not-allowed': loading}"
                                        class="inline-flex items-center px-4 py-2 mx-auto text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                                    >
                                        <svg v-if="loading" class="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    Exibir
                                    </button>

                                </span>

                                <span
                                    v-if="
                                        !storeProduct.is_hidden
                                    "
                                    class="flex flex-wrap"
                                >
                                        <CheckCircleIcon
                                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                            aria-hidden="true"
                                        />
                                        Produto Visível

                                        <button
                                            @click.prevent="toggle"
                                            type="button"
                                            :class="{'cursor-not-allowed': loading}"
                                            class="inline-flex items-center px-4 py-2 mx-auto text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                                            >
                                            <svg v-if="loading" class="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Ocultar
                                        </button>
                                </span>
                            </span>
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >CATEGORIA</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">{{
                                storeProduct.category.name
                            }}</span>
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >TIPO</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">{{
                                storeProduct.type
                            }}</span>
                        </div>
                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >P. Reembolso</span
                            >
                            <span class="text-gray-600 dark:text-gray-200"
                                >{{ storeProduct.refund_deadline }} Dias</span
                            >
                        </div>
                        <div class="flex flex-col col-span-4 my-2">
                            <span class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400">
                                Comprovante de Estoque
                            </span>
                            <span
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-if="storeProduct.image_stock"
                            >
                                <a
                                    :href="
                                       storeProduct.image_stock
                                    "
                                    target="_blank"
                                    v-if="storeProduct.image_stock"
                                >
                                    Clique e visualize aqui!
                                </a>
                            </span>
                            <span
                                class="text-sm font-medium py-1.5 text-red-500"
                                v-else
                            >
                                Não possui
                            </span>
                        </div>
                        <div class="flex flex-col col-span-4 my-2">
                            <span class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400">
                                Página de Vendas
                            </span>
                            <span
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-if="storeProduct.metadata?.links?.sales_page_url"
                            >
                                <a
                                    :href="
                                        storeProduct.metadata?.links
                                            ?.sales_page_url
                                    "
                                    target="_blank"
                                    v-if="storeProduct.metadata?.links?.sales_page_url"
                                >
                                    {{ storeProduct.metadata?.links?.sales_page_url }}
                                </a>
                            </span>
                            <span
                                class="text-sm font-medium py-1.5 text-red-500"
                                v-else
                            >
                                Não possui
                            </span>
                        </div>


                        <div class="flex flex-col col-span-4 my-2" v-if="storeProduct.metadata?.links?.without_sales_page">
                            <span class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400">
                                Motivo de Não Possuir a Página de Vendas
                            </span>
                            <span
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-if="storeProduct.metadata?.links?.without_sales_page"
                            >
                                {{ storeProduct.metadata?.links?.sales_page_absence_reason }}
                            </span>
                            <span
                                class="text-sm font-medium py-1.5 text-red-500"
                                v-else
                            >
                                Não informado
                            </span>
                        </div>
                        <div class="flex flex-col col-span-4 my-2" v-if="storeProduct.metadata?.links?.page_released">
                            <span class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400">
                                Onde será divulgado o seu produto?
                            </span>
                            <span
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-if="storeProduct.metadata?.links?.page_released"
                            >
                                {{ storeProduct.metadata?.links?.page_released }}
                            </span>
                            <span
                                class="text-sm font-medium py-1.5 text-red-500"
                                v-else
                            >
                                Não informado
                            </span>
                        </div>
                        <div class="flex flex-col col-span-4 my-2">
                            <span class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400">
                                Descrição
                            </span>
                            <textarea
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-model="storeProduct.description"
                                disabled
                            ></textarea>
                        </div>
                        <div class="flex flex-col col-span-4 my-2">
                            <span class="block text-sm font-bold text-gray-600 uppercase dark:text-gray-400 display-block d-block">
                                Área de Membros (Mozart)
                            </span>

                            <div
                                class="flex flex-col"
                                v-if="
                                    !storeProduct.members_portal_id
                                "
                            >
                                <span
                                    class="text-sm font-medium py-1.5 text-red-500"
                                >
                                    Não possui
                                </span>
                            </div>
                            <div v-else class="mt-2">
                                <span
                                    class="text-sm font-medium px-2.5 py-1.5 rounded bg-green-500 text-white"
                                >
                                    <button @click.prevent="mozartAccess">
                                        Acessar
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-col col-span-4 my-2">
                            <span class="block text-sm font-bold text-gray-600 uppercase dark:text-gray-400 display-block d-block">
                                Área de Membros Externa (Webhook)
                            </span>

                            <div
                                class="flex flex-col"
                                v-if="
                                    !storeProduct.metadata
                                        ?.external_members_area
                                "
                            >
                                <span
                                    class="text-sm font-medium py-1.5 text-red-500"
                                >
                                    Não possui
                                </span>
                            </div>
                        </div>
                        <div
                            class="flex flex-col col-span-4"
                            v-if="storeProduct.metadata?.external_members_area"
                        >
                            <span
                                class="p-4 text-gray-600 rounded-lg dark:text-gray-200 bg-slate-900"
                            >
                                Link:
                                <span
                                    class="text-sm font-medium px-2.5 py-1.5 rounded bg-green-500 text-white"
                                >
                                    <a
                                        :href="
                                            storeProduct.metadata
                                                ?.external_members_area?.url
                                        "
                                        target="_blank"
                                        >Acessar</a
                                    >
                                </span>

                                <br />
                                Login:
                                {{
                                    storeProduct.metadata?.external_members_area
                                        ?.login
                                }}
                                <br />
                                Senha:
                                {{
                                    storeProduct.metadata?.external_members_area
                                        ?.password
                                }}
                            </span>
                        </div>

                          <div class="flex flex-col col-span-4 my-2">
                            <span class="block text-sm font-bold text-gray-600 uppercase dark:text-gray-400 display-block d-block">
                               Integrações deste produto
                            </span>

                            <div
                                class="flex flex-col"
                                v-if="
                                    !integrations || !integrations?.length
                                "
                            >
                                <span
                                    class="text-sm font-medium py-1.5 text-red-500"
                                >
                                    Sem integrações
                                </span>
                            </div>
                        </div>

                            <div
                                class="flex flex-col col-span-4"
                                v-for="integration in integrations"
                                v-if="integration !== null"
                                :key="integration.id"
                            >
                                <span
                                    class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                >
                                    Integração: {{integration.type ?? integration.name}}
                                    <br />
                                    Token: {{integration.options.token ?? integration.options.api_key}}
                                    <br />
                                    Criado em: {{integration.created_at}}
                                </span>
                            </div>



                        <div class="flex flex-col">
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >P. de Afiliados</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">{{
                                storeProduct.allow_affiliation ? "Sim" : "Não"
                            }}</span>
                        </div>
                        <div
                            class="flex flex-col"
                            v-if="storeProduct.allow_affiliation"
                        >
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >Af. Global</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">{{
                                storeProduct.allow_global_affiliation
                                    ? "Sim"
                                    : "Não"
                            }}</span>
                        </div>
                        <div
                            class="flex flex-col"
                            v-if="storeProduct.allow_affiliation"
                        >
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >Aprov. Automática</span
                            >
                            <span class="text-gray-600 dark:text-gray-200">{{
                                storeProduct.has_auto_affiliation
                                    ? "Sim"
                                    : "Não"
                            }}</span>
                        </div>
                        <div
                            class="flex flex-col"
                            v-if="storeProduct.allow_affiliation"
                        >
                            <span class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400">
                                Comissão
                            </span>
                            <span class="text-gray-600 dark:text-gray-200">
                                {{ storeProduct.affiliate_percentage }}%
                                {{ storeProduct.affiliate_commission_type }}
                                click.
                            </span>
                        </div>
                        <div
                            class="flex flex-col col-span-4 my-2"
                            v-if="storeProduct.allow_affiliation"
                        >
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >Descrição</span
                            >
                            <textarea
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-html="storeProduct.affiliation_description"
                                disabled
                            ></textarea>
                        </div>
                        <div
                            class="flex flex-col col-span-4 mb-2"
                            v-if="storeProduct.allow_affiliation"
                        >
                            <span
                                class="text-sm font-bold text-gray-600 uppercase dark:text-gray-400"
                                >Termos</span
                            >
                            <span
                                class="p-4 text-gray-600 bg-gray-100 rounded-lg dark:text-gray-200 dark:bg-slate-900"
                                v-html="storeProduct.affiliation_terms"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <offers :product="storeProduct"></offers>
        </div>
        <div>
            <product-ebook
                v-if="storeProduct.type === 'ebook' || storeProduct.has_ebooks == true"
                :product="storeProduct"
            />

            <div
                class="flex w-full min-w-0 p-5 mb-4 overflow-hidden bg-white rounded-lg h-50 dark:bg-gray-800 drop-shadow-sm"
            >
                <div class="flex w-full" role="status" v-if="loading">
                    <svg
                        aria-hidden="true"
                        class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-400 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
                <ol
                    v-else
                    class="relative w-full border-l border-gray-200 dark:border-gray-700"
                >
                    <li class="mb-10 ml-4" v-for="status in statuses">
                        <div
                            class="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900"
                            :class="{
                                'bg-white': status.status === 'draft',
                                'bg-green-500': status.status === 'approved',
                                'bg-red-500': status.status === 'reproved',
                                'bg-orange-500': status.status === 'pending-correction',
                                'bg-yellow-500': status.status === 'pending',
                            }"
                        ></div>
                        <time
                            class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                            >{{ status.created_at }}</time
                        >
                        <h3
                            class="text-lg font-semibold text-gray-900 dark:text-white"
                        >
                            {{ getStatusName(status.status) }}
                        </h3>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200"
                        >
                            {{ status.user.name }} {{ status.user.email }}
                        </p>
                        <textarea
                            v-if="status.description"
                            class="w-full p-4 mt-1 text-base font-normal text-gray-500 rounded-lg bg-slate-900 dark:text-gray-200"
                            disabled="true"
                        >{{ status.description }}</textarea>
                    </li>
                    <li class="ml-4">
                        <div
                            class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
                        ></div>
                        <time
                            class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                            >{{ storeProduct.created_at }}</time
                        >
                        <h3
                            class="text-lg font-semibold text-gray-900 dark:text-white"
                        >
                            Produto Criado
                        </h3>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200"
                        >
                            <a
                                v-if="storeProduct.status !== 'approved' || hasPermission('revert.status.products')"
                                @click="approve = !approve"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                            >
                                Aprovar/Reprovar
                                <svg
                                    class="w-3 h-3 ml-2"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </a>
                        </p>
                    </li>
                </ol>
            </div>
            <TransitionRoot
                appear
                :show="approve"
                enter="transition-opacity duration-75"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity duration-150"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="w-full min-w-0 p-5 mb-4 overflow-hidden bg-white rounded-lg h-50 dark:bg-gray-800 drop-shadow-sm"
                >
                    <h3
                        class="text-lg font-semibold text-gray-900 dark:text-white"
                    >
                        Aprovar/Reprovar
                    </h3>
                    <label
                        for="countries"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200"
                        >Selecione uma opção</label
                    >
                    <select
                        v-model="form.status"
                        id="countries"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                        <option :value="false" selected>Status</option>
                        <option value="approved">Aprovado</option>
                        <option value="pending-correction">Correção Pendente</option>
                        <option value="reproved">Reprovado</option>
                    </select>

                    <div v-if="['reproved', 'pending-correction'].includes(form.status)">
                        <div>
                            <ReasonSelect reasonType="product" @reason-selected="handleReasonSelected"/>
                        </div>
                    </div>

                    <button
                        v-if="form.status"
                        @click.prevent="submit"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="inline-flex items-center justify-center w-full px-4 py-2 mx-auto mt-5 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                    >
                        <svg
                            v-if="loading"
                            class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Enviar
                    </button>
                </div>
            </TransitionRoot>
        </div>
    </div>

    <edit-product-modal
        :product="storeProduct"
        v-if="edit_product_modal"
        @closeModal="closeModal()"
        @closeModalAndFetch="closeAndFetchModal()"
    />
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { mapActions, mapWritableState } from "pinia";
import { useToast } from "vue-toastification";
import NavTabs from "../../components/layouts/NavTabs.vue";
import ProductStatus from "../../components/layouts/ProductStatus.vue";
import ReasonSelect from "../../components/layouts/ReasonSelect.vue";
import BackButton from "../../components/layouts/navigation/BackButton.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";
import { Store } from "../../stores/store.js";
import UserHeading from "../users/UserHeading.vue";
import Offers from "./Offers.vue";
import ProductEbook from "./ProductEbook.vue";
import EditProductModal from "./modals/EditProductModal.vue";

export default {
    components: { BackButton, ProductEbook, ProductStatus, Offers, NavTabs, TransitionRoot, UserHeading, EditProductModal, ReasonSelect, CheckCircleIcon },
    mixins: [HelperMixin, PermissionMixin],
     setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            tabs: [
                {
                    name: "details",
                    label: "Detalhes",
                    route: "product",
                    params: { product_id: this.$route.params.product_id },
                },
                {
                    name: "students",
                    label: "Alunos",
                    route: "product.students",
                    params: { product_id: this.$route.params.product_id },
                },
                {
                    name: "members",
                    label: "Area de membros",
                    route: "product.members.contents",
                    params: { product_id: this.$route.params.product_id },
                }
            ],
            approve: false,
            loading: false,
            integrations: false,
            edit_product_modal: false,
            statuses: [],
            form: {
                status: false,
                description: "",
            },
        };
    },
    computed: {
        ...mapWritableState(Store, ["storeProduct", "permissions"]),
    },

    async mounted() {
        if (!this.storeProduct) {
            await this.fetchProduct();
        }

        this.fetch();
        this.fetchIntegrations();
    },

    methods: {
        ...mapActions(Store, ["fetchProduct"]),
        fetch() {
            this.loading = true;
            axios
                .get("/api/product/" + this.$route.params.product_id + "/status")
                .then(({ data }) => {
                    this.statuses = data;
                    this.loading = false;
                });
        },
        toggle() {
            this.loading = true;
            axios.post('/api/product/' + this.$route.params.product_id + '/toggle').then(({data}) => {
                this.fetchProduct();
                this.fetch()
                this.loading = false;
            })
        },
        fetchIntegrations() {
            this.loading = true;
            axios
                .get("/api/product/" + this.storeProduct.id + "/integrations")
                .then(({ data }) => {
                    this.integrations = data;
                    this.loading = false;
                });
        },
        async mozartAccess() {
            this.loading = true;
            let token = null;
            let portalUrl = null;

            await axios.get(`/api/product/${this.storeProduct.id}/mozart-access-token`).then(({ data }) => {
                token = data.token;
                portalUrl = data.portal_url;
                this.loading = false;
            }).catch(async (error) => {
                this.toast.error("Houve um erro ao redirecionar");
                this.loading = false;
            });

            window.open(portalUrl + `/token-login?token=${token}`, "_blank");
        },
        openModal() {
            this.edit_product_modal = true;
        },
        closeModal() {
            this.edit_product_modal = false;
        },
        closeAndFetchModal() {
            this.edit_product_modal = false;
            this.fetchProduct();
        },
        submit() {
            axios
                .post(
                    "/api/product/" + this.storeProduct.id + "/status",
                    this.form
                )
                .then(({ data }) => {
                    this.approve = false;
                    this.storeProduct = data;
                    this.fetch();
                    this.fetchProduct();
                }).catch(async (error) => {
                    this.toast.error(error.response.data.errors[0]);
                    this.approve = false;
                });

            this.form = {
                status: false,
                description: "",
            };
        },
        handleReasonSelected(reason) {
            this.form.description = reason;
        },
    },
};
</script>
