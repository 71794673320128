<template>
    <div v-if="!loading && transactions.length > 0">
        <div class="flex">
            <h3 class="mt-4 text-lg font-bold font-medium text-gray-400">
                Cobranças
            </h3>
            <div class="mb-1 ml-auto mt-3">
                <Menu as="div" class="relative z-10 ml-3">
                    <div>
                        <MenuButton
                            class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none"
                            v-slot="{ open }"
                        >
                            <span class="sr-only">Open user menu</span>
                            <button class="btn-primary">
                                Ações
                                <ChevronDownIcon
                                    class="ml-1 w-4 transition-all ease-in-out"
                                    :class="{ 'rotate-180': open }"
                                    aria-hidden="true"
                                />
                            </button>
                        </MenuButton>
                    </div>
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                    >
                        <MenuItems
                            class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <MenuItem v-slot="{ active }">
                                <a
                                    target="_blank"
                                    :href="'/api/order/' + order.id + '/export'"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Exportar
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }">
                                <a
                                    target="_blank"
                                    :href="
                                        '/api/order/' +
                                        order.id +
                                        '/exportFinancial'
                                    "
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Exportar Completo
                                </a>
                            </MenuItem>
                            <MenuItem
                                v-slot="{ active }"
                                v-if="getDetailsSales(order)"
                            >
                                <button
                                    @click.prevent="
                                        resendEmailPurchase(order.id)
                                    "
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Reenviar E-mail de Aprovação
                                </button>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" v-if="canAddToBlacklist(order)">
                                <button
                                    @click.prevent="openModalAddToBlacklist()"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Adicionar à Blacklist
                                </button>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" v-if="canRemoveFromBlacklist(order)">
                                <button
                                    @click.prevent="openModalBlacklistDetail()"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Remover da Blacklist
                                </button>
                            </MenuItem>
                            <MenuItem v-slot="{ active }">
                                <a
                                    target="_blank"
                                    :href="
                                        '/api/order/' +
                                        order.id +
                                        '/exportRefundLetter'
                                    "
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Imprimir Carta de Reembolso
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" v-if="showChargebackLetter(order)">
                                <a
                                    target="_blank"
                                    :href="
                                        '/api/order/' +
                                        order.id +
                                        '/exportChargebackLetter'
                                    "
                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                >
                                    Imprimir Carta de Chargeback
                                </a>
                            </MenuItem>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>
        <div class="mb-2 mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <div
                    class="relative overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25"
                >
                    <table class="w-full table-auto border-collapse text-sm">
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Ações
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Hash da Transação
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Código do Gateway
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Valor da Cobrança
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Valor Pago
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Ocorrência
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Data da Cobrança
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                >
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white dark:bg-slate-800">
                            <tr v-for="(transaction, key) in transactions">
                                <td
                                    class="border-b border-slate-100 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <Menu as="div" class="relative ml-3">
                                        <div>
                                            <MenuButton
                                                class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none"
                                                v-slot="{ open }"
                                            >
                                                <button class="btn-action">
                                                    <ChevronDownIcon
                                                        class="w-4 transition-all ease-in-out"
                                                        :class="{
                                                            'rotate-180': open,
                                                        }"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </MenuButton>
                                        </div>
                                        <transition
                                            enter-active-class="transition ease-out duration-100"
                                            enter-from-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-from-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95"
                                        >
                                            <MenuItems
                                                class="fixed z-10 mt-2 w-64 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            >
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canBeRefunded(transaction)
                                                    "
                                                >
                                                    <button
                                                        @click.prevent="
                                                            openRefundModal(
                                                                transaction,
                                                                false,
                                                            )
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Reembolsar
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canBeCanceled(
                                                            transaction,
                                                            key,
                                                        )
                                                    "
                                                >
                                                    <button
                                                        @click.prevent="
                                                            openRefundModal(
                                                                transaction,
                                                                true,
                                                            )
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Reembolsar e Cancelar
                                                        Próximas
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canBeExternalRefunded(
                                                            transaction,
                                                        )
                                                    "
                                                >
                                                    <button
                                                        v-if="
                                                            hasPermission(
                                                                'confirm-fraud.audits',
                                                            )
                                                        "
                                                        @click.prevent="
                                                            openModalFraudRefund(
                                                                transaction,
                                                            )
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Reembolsar e Sinalizar
                                                        Fraude
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canBeCanceled(transaction, key)
                                                    "
                                                >
                                                    <button
                                                        @click.prevent="
                                                            refund(
                                                                transaction,
                                                                true,
                                                            )
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Reembolsar e Cancelar
                                                        Próximas
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canBeExternalRefunded(transaction)
                                                    "
                                                >
                                                    <button
                                                        @click.prevent="
                                                            openModalExternalRefund(
                                                                transaction,
                                                            )
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Sinalizar Reembolso
                                                        Externo
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canAddToBlacklist(transaction?.order)
                                                    "
                                                >
                                                    <button
                                                        @click.prevent="
                                                            openModalAddToBlacklist()
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Adicionar à Blacklist
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        canRemoveFromBlacklist(transaction?.order)
                                                    "
                                                >
                                                    <button
                                                        @click.prevent="
                                                            openModalBlacklistDetail()
                                                        "
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                    >
                                                        Remover da Blacklist
                                                    </button>
                                                </MenuItem>
                                                <MenuItem
                                                    as="div"
                                                    v-slot="{ active }"
                                                    v-if="
                                                        !canBeRefunded(transaction) &&
                                                        !canBeCanceled(transaction, key) &&
                                                        !canBeExternalRefunded(transaction) &&
                                                        !canAddToBlacklist(transaction?.order) &&
                                                        !canRemoveFromBlacklist(transaction?.order)
                                                    "
                                                >
                                                    <button
                                                        class="block cursor-not-allowed px-4 py-2 text-sm text-gray-700"
                                                    >
                                                        Indisponível
                                                    </button>
                                                </MenuItem>
                                            </MenuItems>
                                        </transition>
                                    </Menu>
                                    <blacklist-modal
                                        :order="transaction.order"
                                        v-if="blacklist_modal"
                                        @closeModal="closeModal()"
                                        @closeModalAndFetch="
                                            closeAndFetchModal()
                                        "
                                    />
                                    <blacklist-detail-modal
                                        :visitor-id="transaction.order.visitor_id"
                                        :order="transaction.order"
                                        v-if="blacklist_detail_modal"
                                        @closeModal="closeModal()"
                                        @closeModalAndFetch="
                                            closeAndFetchModal()
                                        "
                                    />
                                    <external-refund-modal
                                        :transaction="
                                            external_refund_transaction
                                        "
                                        :show="external_refund_modal"
                                        v-if="external_refund_modal"
                                        @closeModal="closeModal()"
                                        @closeModalAndFetch="
                                            closeAndFetchModal()
                                        "
                                    />
                                    <fraud-refund-modal
                                        :transaction="fraud_refund_transaction"
                                        :show="fraud_refund_modal"
                                        v-if="fraud_refund_modal"
                                        @closeModal="closeModal"
                                        @closeModalAndFetch="closeAndFetchModal"
                                    />
                                    <refund-modal
                                        :transaction="transaction"
                                        :ask-cancel="ask_cancel"
                                        :order="order"
                                        :show="refund_modal"
                                        v-if="refund_modal"
                                        @closeModal="closeModal"
                                        @closeModalAndFetch="closeAndFetchModal"
                                    />
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ transaction.hash }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{
                                        transaction.external_id
                                            ? transaction.external_id
                                                  .replace("CHAR_", "")
                                                  .replace("ORDE_", "")
                                            : "-"
                                    }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ getOrderAmount(transaction) }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{
                                        $root.formatCurrency(
                                            transaction.paid_amount,
                                        )
                                    }}
                                    <small v-if="getInstallments(transaction)"
                                        ><br />{{
                                            getInstallments(transaction)
                                        }}</small
                                    >
                                    <small v-if="transaction.international_paid_amount"
                                        ><br />({{
                                            $root.formatOtherCurrency(
                                                transaction.international_paid_amount,
                                                transaction.currency
                                            )
                                        }})</small
                                    >
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ getOccurence(transaction) }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ transaction.created_at }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <status
                                        :status="transaction.status"
                                    ></status>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="my-5 flex w-full"
                        v-if="pagination.last_page > 1"
                    >
                        <div class="mx-auto flex flex-col items-center">
                            <span class="mb-1">
                                Exibindo {{ pagination.from }} a
                                {{ pagination.to }} registros de
                                {{ pagination.total }}
                            </span>
                            <pagination
                                :pagination="pagination"
                                :offset="1"
                                @paginate="fetch"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h3 class="my-3 text-lg font-bold font-medium text-gray-400">
            Buscando transações...
        </h3>
    </div>
</template>

<script>
import {
Disclosure,
DisclosureButton,
DisclosurePanel,
Menu,
MenuButton,
MenuItem,
MenuItems,
} from "@headlessui/vue";
import {
ChevronDownIcon,
ReceiptRefundIcon,
ShieldCheckIcon,
ShieldExclamationIcon,
XCircleIcon,
} from "@heroicons/vue/24/outline";
import { mapState } from "pinia";
import Pagination from "../../components/layouts/Pagination.vue";
import Status from "../../components/layouts/Status.vue";
import { Store } from "../../stores/store";
import BlacklistDetailModal from "../blacklist/modals/BlacklistDetailModal.vue";
import BlacklistModal from "../blacklist/modals/BlacklistModal.vue";
import ExternalRefundModal from "./modals/ExternalRefundModal.vue";
import FraudRefundModal from "./modals/FraudRefundModal.vue";
import RefundModal from "./modals/RefundModal.vue";

export default {
    components: {
        RefundModal,
        FraudRefundModal,
        ExternalRefundModal,
        Pagination,
        Status,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ReceiptRefundIcon,
        ChevronDownIcon,
        XCircleIcon,
        ShieldExclamationIcon,
        ShieldCheckIcon,
        BlacklistDetailModal,
        BlacklistModal,
    },
    props: ["order"],
    data() {
        return {
            fraud_refund_transaction: false,
            fraud_refund_modal: false,
            cancel_already_displayed: 0,
            loading: false,
            next_page_url: false,
            transactions: [],
            refund_modal: false,
            ask_cancel: false,
            blacklist_modal: false,
            blacklist_detail_modal: false,
            external_refund_modal: false,
            external_refund_transaction: null,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    mounted() {
        this.fetch();
    },

    computed: {
        ...mapState(Store, ["permissions"]),
    },

    methods: {
        fetch() {
            this.loading = true;

            const params = {};
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/order/" + this.order.id + "/transactions", {
                    params: params,
                })
                .then(({ data }) => {
                    this.transactions = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        canBeCanceled(transaction, key) {
            return (
                this.canBeRefunded(transaction) &&
                this.order.subscription &&
                !this.order.subscription.canceled_at &&
                !key
            );
        },
        canBeRefunded(transaction) {
            return (
                transaction.status == "authorized" ||
                transaction.status == "refund_requested"
            );
        },
        canBeExternalRefunded(transaction) {
            return !transaction.external_refund_id;
        },
        canAddToBlacklist(order) {
            return (
                this.hasPermission("create.blacklist") &&
                !order.is_on_blacklist &&
                order.visitor_id
            );
        },
        canRemoveFromBlacklist(order) {
            return (
                this.hasPermission("create.blacklist") &&
                order.is_on_blacklist
            );
        },
        showChargebackLetter(order) {
            return Object.values(order.transactions).some((transaction) => {
                if (['disputed','chargeback'].includes(transaction.status)) {
                    return true;
                }
            });
        },
        getOrderAmount(transaction) {
            if (this.order.is_two_cards) {
                if (transaction.is_card2) {
                    return this.$root.formatCurrency(this.order.amount_card2);
                }
            } else if (transaction.occurrence == 1) {
                return this.$root.formatCurrency(
                    this.order.first_charge_amount,
                );
            }
            return this.$root.formatCurrency(this.order.amount);
        },
        getInstallments(transaction) {
            if (
                this.order.subscription &&
                this.order.subscription.max_charges > 1
            ) {
                return 0;
            } else if (transaction.is_card2) {
                if (this.order.installments_card2 > 1) {
                    return "(em " + this.order.installments_card2 + "x)";
                }
            } else {
                if (this.order.installments > 1) {
                    if (this.order.payment_method === "bank_slip") {
                        return 0;
                    }
                    return "(em " + this.order.installments + "x)";
                }
            }
            return 0;
        },
        getDetailsSales(order) {
            if (order?.status === "authorized") {
                const orderCreatedAt = order.commissions[0].created_at;
                const dateFormatedISO = orderCreatedAt.split(" ")[0];
                const splitDate = dateFormatedISO.split("/");

                const dateCurrent = new Date();
                const dataIso = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

                const dateLimit = new Date(dataIso);
                dateLimit.setDate(dateLimit.getDate() + 7);
                dateLimit.setHours(23);
                dateLimit.setMinutes(59);

                const dateSale = new Date(dataIso);
                dateSale.setDate(dateSale.getDate() + 1);
                dateSale.setHours(dateCurrent.getHours());

                if (
                    dateCurrent >= dateSale &&
                    dateSale <= dateLimit &&
                    dateCurrent <= dateLimit
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        getOccurence(transaction) {
            if (this.order.is_two_cards) {
                if (transaction.is_card2) {
                    return "2º Cartão";
                }
                return "1º Cartão";
            } else if (this.order.subscription) {
                if (this.order.subscription.max_charges > 1) {
                    return (
                        transaction.occurrence +
                        "/" +
                        this.order.subscription.max_charges
                    );
                } else if (
                    this.order.payment_method === "bank_slip" &&
                    this.order.installments > 1
                ) {
                    return (
                        transaction.occurrence + "/" + this.order.installments
                    );
                }
            }
            return transaction.occurrence;
        },
        openRefundModal(transaction, askCancel) {
            this.refund_modal = true;
            this.ask_cancel = askCancel;
            this.transaction = transaction;
        },
        refund(transaction, askCancel) {
            let message = "Tem certeza que deseja reembolsar essa cobrança?";
            let cancel = false;
            if (askCancel) {
                message = "Tem certeza que deseja reembolsar essa cobrança e cancelar todas as próximas cobranças desse pedido?";
                cancel = true;
            }

            if (confirm(message)) {
                this.loading = true;

                axios
                    .post(
                        "/api/order/" +
                            this.order.id +
                            "/transactions/" +
                            transaction.id +
                            "/refund",
                        {
                            permissions: this.permissions,
                            cancel: cancel,
                        },
                    )
                    .then(({ data }) => {
                        this.$notify(
                            {
                                group: "success",
                                title: "Success",
                                text:
                                    data?.message ||
                                    "Solicitação realizada com sucesso!",
                            },
                            4000,
                        );

                        this.fetch();
                    })
                    .catch(({ response }) => {
                        this.$notify(
                            {
                                group: "error",
                                title: "Error",
                                text:
                                    response?.data?.message ||
                                    response?.data?.errors[0] ||
                                    "Não foi possível concluir essa solicitação.",
                            },
                            4000,
                        );

                        this.loading = false;
                    });
            }
        },
        resendEmailPurchase(order) {
            this.loading = true;

            axios
                .get("/api/order/" + order + "/resend-email-purchase")
                .then(({ data }) => {
                    this.$notify(
                        {
                            group: "success",
                            title: "Success",
                            text:
                                data?.message ||
                                "Solicitação realizada com sucesso!",
                        },
                        4000,
                    );

                    this.fetch();
                })
                .catch(({ response }) => {
                    this.$notify(
                        {
                            group: "error",
                            title: "Error",
                            text:
                                response?.data?.message ||
                                response?.data?.errors[0] ||
                                "Não foi possível concluir essa solicitação.",
                        },
                        4000,
                    );

                    this.loading = false;
                });
        },
        openModalAddToBlacklist() {
            this.blacklist_modal = true;
        },
        openModalBlacklistDetail() {
            this.blacklist_detail_modal = true;
        },
        openModalFraudRefund(transaction) {
            this.fraud_refund_transaction = transaction;
            this.fraud_refund_modal = true;
        },
        openModalExternalRefund(transaction) {
            this.external_refund_transaction = transaction;
            this.external_refund_modal = true;
        },
        closeModal() {
            this.blacklist_modal = false;
            this.blacklist_detail_modal = false;
            this.external_refund_modal = false;
            this.fraud_refund_modal = false;
            this.refund_modal = false;
        },
        closeAndFetchModal() {
            this.blacklist_modal = false;
            this.blacklist_detail_modal = false;
            this.external_refund_modal = false;
            this.fraud_refund_modal = false;
            this.refund_modal = false;
            this.fetch();
            this.$emit("updated");
        },
        hasPermission(name) {
            if (!this.permissions) {
                return false;
            }
            return this.permissions.find(
                (permission) => permission.name === name,
            );
        },
    },
};
</script>
